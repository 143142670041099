import { Box, Button, Container, styledComponent, Typography } from '@hermes/web-components'

export const SlideHeaderWrapper = styledComponent(Container, {
  shouldForwardProp: (prop) => prop !== 'hasHeadingId'
})<{ hasHeadingId: boolean }>(({ hasHeadingId }) => ({
  display: 'flex',
  position: 'relative',
  zIndex: 1,
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  '.rich-text': {
    maxWidth: '750px'
  },
  ...(hasHeadingId && {
    alignItems: 'flex-start',
    '&.MuiContainer-root': {
      padding: 0
    },
    textAlign: 'left'
  })
}))

export const SlideHeaderTitle = styledComponent(Typography, { shouldForwardProp: (prop) => prop !== 'hasHeadingId' })<{
  component: string
  hasHeadingId: boolean
}>(({ theme, hasHeadingId }) => ({
  fontSize: '36px',
  lineHeight: '42px',
  color: theme.palette.text.primary,
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    textAlign: hasHeadingId ? 'left' : 'center',
    fontSize: '28px',
    lineHeight: '34px'
  }
}))

export const SlideHeaderLink = styledComponent(Button)(({ theme }) => ({
  height: '40px',
  width: '40px',
  aspectRatio: '1/1',
  minWidth: 'auto',
  padding: 0,
  borderRadius: '50%',
  border: `0.1px solid ${theme.palette.additional.border}`
}))

export const HeaderContent = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'hoverEnabled'
})<{ hoverEnabled?: boolean; href?: string }>(({ theme, hoverEnabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  ...(hoverEnabled
    ? {
        cursor: 'pointer',
        '&:hover': {
          '.MuiTypography-root': {
            background: 'linear-gradient(270deg, #35519E -24.29%, #46BED9 144.64%)',
            webkitBackgroundClip: 'text',
            backgroundClip: 'text',
            webkitTextFillColor: 'transparent',
            textFillColor: 'transparent'
          },
          '.MuiButton-root': {
            border: '1px solid transparent',
            backgroundColor: theme.palette.primary.main,
            svg: {
              fill: theme.palette.typography.main
            }
          }
        }
      }
    : {})
}))

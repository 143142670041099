import { Box, styledComponent } from '@hermes/web-components'

const SectionsContainer = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  gap: '66px',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    gap: '30px',
    padding: '12px 0 0 0'
  }
}))

export default SectionsContainer

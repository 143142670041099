import { Button, Typography } from '@hermes/web-components'
import NextLink from 'next/link'
import { GetInTouchContainer } from './styles'
import useTranslateMessage from '../../hooks/useTranslateMessage'

export interface GetInTouchProps {
  title: string
  description: string
}

const GetInTouch = ({ title, description }: GetInTouchProps) => {
  const t = useTranslateMessage()
  return (
    <GetInTouchContainer maxWidth="lg">
      <Typography
        variant="h2"
        component="p"
        fontWeight="400"
        fontSize="36px"
        lineHeight="34px"
        sx={(theme) => ({
          paddingBottom: '28px',
          textTransform: 'capitalize',
          [theme.breakpoints.down('sm')]: {
            fontSize: '28px'
          }
        })}
        dangerouslySetInnerHTML={{
          __html: title
        }}
      />
      <Typography
        sx={{
          paddingBottom: '42px'
        }}
      >
        {description}
      </Typography>
      <NextLink href="/contact">
        <a>
          <Button
            variant="outlined"
            sx={{
              minWidth: '200px',
              padding: '18px 0'
            }}
          >
            {t('getInTouch.form.button')}
          </Button>
        </a>
      </NextLink>
    </GetInTouchContainer>
  )
}

export default GetInTouch

import GetInTouch, { GetInTouchProps } from '../../GetInTouch'
import { GetInTouchContainer } from './styles'

const GetInTouchSection = (props: GetInTouchProps) => (
  <GetInTouchContainer id="get-in-touch-message">
    <GetInTouch {...props} />
  </GetInTouchContainer>
)

export default GetInTouchSection

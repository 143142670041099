import { Hidden } from '@hermes/web-components'
import { SxProps, Theme } from '@mui/system'
import ArrowRightIcon from '@hermes/web-components/src/icons/ArrowRight'
import { animated, useInView } from '@react-spring/web'
import NextLink from 'next/link'
import { HeaderContent, SlideHeaderLink, SlideHeaderTitle, SlideHeaderWrapper } from './styles'
import RichText from '../RichText'

interface SlideHeaderProps {
  id?: string
  headingTitle: string
  headingSubtitle?: string
  headingSubtitleAlign?: 'center' | 'left' | 'right'
  headingId?: string
  href?: string
  sx?: SxProps<Theme>
  titleStyles?: SxProps<Theme>
}

const SlideHeader = ({
  id,
  headingTitle,
  sx,
  href,
  headingSubtitle,
  titleStyles,
  headingSubtitleAlign = 'left',
  headingId
}: SlideHeaderProps) => {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    }),
    {
      rootMargin: '-50px 0px'
    }
  )
  const AnimatedStyleHeaderTitle = animated(SlideHeaderTitle)

  const headerContent = (
    <HeaderContent component={href?.length ? 'a' : 'div'} hoverEnabled={!!href?.length}>
      <AnimatedStyleHeaderTitle
        sx={titleStyles}
        ref={ref}
        style={springs}
        variant="h1"
        component="h2"
        hasHeadingId={!!headingId}
        dangerouslySetInnerHTML={{ __html: headingTitle }}
      />
      <Hidden smDown>
        {href && (
          <SlideHeaderLink variant="outlined">
            <ArrowRightIcon width="17px" />
          </SlideHeaderLink>
        )}
      </Hidden>
    </HeaderContent>
  )

  return (
    <SlideHeaderWrapper
      className={headingId ? 'heading-anchor-article' : undefined}
      hasHeadingId={!!headingId}
      id={`${headingId}-${id}`}
      maxWidth="lg"
      sx={sx}
    >
      {href?.length ? (
        <NextLink href={href} passHref>
          {headerContent}
        </NextLink>
      ) : (
        headerContent
      )}

      {Boolean(headingSubtitle?.length) && (
        <RichText textAlign={headingSubtitleAlign} content={String(headingSubtitle)} />
      )}
    </SlideHeaderWrapper>
  )
}

export default SlideHeader

import { Box, styledComponent } from '@hermes/web-components'

export const ShowcaseCardContainer = styledComponent(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '35% 65%',
  gap: '35px',
  height: '100%',
  textDecoration: 'none',
  color: theme.palette.typography.primary,
  border: '2px solid transparent',
  padding: '35px',
  borderRadius: '20px',
  transition: '0.3s',
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      border: `2px solid ${theme.palette.additional.border}`,
      '.showcase-card-image-container': {
        img: {
          transform: 'scale(1.2)'
        }
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    padding: 0,
    gap: '25px'
  },
  '.showcase-card-content': {
    width: '85%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

export const ShowcaseCardImageContainer = styledComponent(Box)(() => ({
  position: 'relative',
  overflow: 'hidden',
  minHeight: '243px',
  height: '100%',
  width: '100%',
  borderRadius: '14px',
  img: {
    transition: '0.2s',
    position: 'absolute',
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  }
}))

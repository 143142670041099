import { Box, styledComponent } from '@hermes/web-components'

export const StyledRichText = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'contentAlign' && prop !== 'breakpointFullWidth'
})<{
  contentAlign?: 'left' | 'center' | 'right'
  breakpointFullWidth: number
}>(({ theme, contentAlign, breakpointFullWidth }) => ({
  [theme.breakpoints.down(breakpointFullWidth)]: {
    maxWidth: '100%'
  },
  ...(contentAlign === 'left' && {
    textAlign: 'left'
  }),
  ...(contentAlign === 'center' && {
    textAlign: 'center',
    margin: '0 auto'
  }),
  ...(contentAlign === 'right' && {
    textAlign: 'right',
    marginLeft: 'auto',
    marginRight: 0
  })
}))

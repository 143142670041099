import { styledComponent } from '@hermes/web-components'
import Section from '../../Section'

export const GetInTouchContainer = styledComponent(Section)(({ theme }) => ({
  backgroundImage: 'url(/img/background-patterns/desktop/get-in-touch-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'bottom',
  [theme.breakpoints.up('sm')]: {
    padding: '15px 0'
  },
  [theme.breakpoints.down('sm')]: {
    backgroundPositionY: 'top',
    backgroundImage: 'url(/img/background-patterns/mobile/get-in-touch-section.svg)',
    backgroundPositionX: '100%'
  }
}))

import { styledComponent } from '@hermes/web-components'

const Section = styledComponent('section', {
  shouldForwardProp: (prop) => prop !== 'backgroundPattern'
})<{
  backgroundPattern?: string
}>(({ theme, backgroundPattern }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '65px',
  ...(backgroundPattern?.length
    ? {
        backgroundImage: `url(/img/background-patterns/desktop/${backgroundPattern}.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        [theme.breakpoints.down('sm')]: {
          backgroundImage: `url(/img/background-patterns/mobile/${backgroundPattern}.svg)`,
          backgroundSize: '100%',
          backgroundPositionY: '50px'
        }
      }
    : {}),
  [theme.breakpoints.down('sm')]: {
    gap: '30px'
  }
}))

export default Section

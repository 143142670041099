import { Container, Carousel, CarouselSlide } from '@hermes/web-components'
import { ShowcaseCarousel, ShowcasesSectionContainer } from './styles'
import SlideHeader from '../../SlideHeader'
import { StrapiAttributes, StrapiResponse } from '../../../types/strapi'
import { ArticleProps } from '../../../types/article'
import ShowcaseCard from '../../ShowcaseCard'

interface ShowcasesSectionProps {
  id: number
  title: string
  showcases: StrapiResponse<StrapiAttributes<ArticleProps>[]>
}

const ShowcasesSection = ({ title, showcases }: ShowcasesSectionProps) => (
  <ShowcasesSectionContainer id="showcases">
    <SlideHeader headingTitle={title} href={'/case-studies'} />
    <Container maxWidth="lg">
      <ShowcaseCarousel>
        <Carousel
          navigation={showcases.data.length > 2 ? true : undefined}
          spaceBetween={45}
          slidesPerGroup={1}
          slidesPerView={1}
        >
          {showcases.data.map(({ id, attributes }) => (
            <CarouselSlide key={`showcase-card-${id}`}>
              <ShowcaseCard url={`/case-studies/${attributes.slug}`} {...attributes} />
            </CarouselSlide>
          ))}
        </Carousel>
      </ShowcaseCarousel>
    </Container>
  </ShowcasesSectionContainer>
)

export default ShowcasesSection

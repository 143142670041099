import { Container, styledComponent } from '@hermes/web-components'

export const GetInTouchContainer = styledComponent(Container)(({ theme }) => ({
  textAlign: 'center',
  '& > .MuiTypography-root': {
    margin: '0 auto',
    '&:last-of-type': {
      width: '80%',
      '& > br': {
        display: 'none'
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& > .MuiTypography-root': {
      margin: 0,
      '&:last-of-type': {
        display: 'block',
        width: '100%',
        '& > br': {
          display: 'block'
        }
      }
    },
    '& > .MuiButton-root': {
      width: '100%'
    }
  }
}))

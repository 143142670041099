import { SxProps } from '@mui/system'
import convertMarkdownToHtml from '../../utils/markdownParser'
import { StyledRichText } from './styles'

interface RichTextProps {
  content: string
  sx?: SxProps
  textAlign?: 'left' | 'center' | 'right'
  width?: string
  breakpointFullWidth?: number
}

const RichText = ({
  content,
  sx,
  textAlign = 'left',
  width = 'width_100',
  breakpointFullWidth = 720
}: RichTextProps) => {
  const convertedMarkdown = (content?.length ? convertMarkdownToHtml(content) : '').toString().trim()

  return (
    <StyledRichText
      contentAlign={textAlign}
      breakpointFullWidth={breakpointFullWidth || 720}
      className="rich-text"
      maxWidth={`${width?.split('_')[1]}%`}
      sx={sx}
      dangerouslySetInnerHTML={{
        __html: convertedMarkdown
      }}
    />
  )
}

export default RichText

import { Box, styledComponent } from '@hermes/web-components'
import Section from '../../Section'

export const ShowcasesSectionContainer = styledComponent(Section)(({ theme }) => ({
  backgroundImage: 'url(/img/background-patterns/desktop/showcases-section.svg)',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundImage: 'url(/img/background-patterns/mobile/showcases-section.svg)',
    backgroundPositionY: '25px'
  }
}))

export const ShowcaseCarousel = styledComponent(Box)(() => ({}))
